import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tinymce-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Editor, {
      modelValue: _ctx.dataValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataValue) = $event)),
      init: {
					height: 300,
					statusbar: true,
					menubar: false,
					branding: false,
					images_upload_url: 'https://yuegege.cn/ygg/file/upload/tinymce',
					plugins: [
						'advlist autolink link image lists code',
						'charmap print preview hr anchor pagebreak',
						'searchreplace wordcount visualblocks visualchars fullscreen insertdatetime nonbreaking',
						'table emoticons template paste help'
					],
					toolbar: 'undo redo | styleselect | forecolor backcolor bold italic | alignleft aligncenter alignright alignjustify | image link code | emoticons | bullist numlist outdent indent | preview ',
					file_picker_types: 'image',
					images_file_types: 'jpg,jpeg,png',
				}
    }, null, 8, ["modelValue"])
  ]))
}
<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*店名:</label></div></td>
					<td><div class="mb-1">
						<input type="text" class="form-control" v-model="item.name">
					</div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*UID前缀:</label></div></td>
					<td><div class="mb-1">
						<input type="text" class="form-control" v-model="item.prefix">
					</div></td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*城市:</label></div></td>
					<td>
						<div class="mb-1">
							<select class="form-select" aria-label="Default select example" v-model="item.city">
								<option value="深圳" selected>深圳</option>
								<option value="广州">广州</option>
								<option value="无锡">无锡</option>
								<option value="珠海">珠海</option>
								<option value="佛山">佛山</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*地址:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="item.address"></div></td>
				</tr>
        <tr>
          <td><div class="mb-1 td-label-right"><label>收款公司:</label></div></td>
          <td><div class="mb-1"><input type="text" class="form-control" v-model="item.collectCompany"></div></td>
        </tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>描述:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="item.desc"></div></td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>经度:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="item.longitude"></div></td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>纬度:</label></div></td>
					<td><div class="mb-1"><input type="text" class="form-control" v-model="item.latitude"></div></td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="shop-show">是否显示:</label>
						</div>
					</td>
					<td>
						<div class="mb-1 form-check form-switch" style="padding-top: 10px;">
							<input class="form-check-input" checked type="checkbox" role="switch" v-model="item.show">
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mb-1 td-label-right">
							<label for="shop-image">*宣传图:</label>
						</div>
					</td>
					<td>
						<div class="mb-1">
							<ImageUploader :src='item.image' @upload='imageUpload' />
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
	<div id="datePickerContainerId"></div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, dateFtt } from '@/common/util';
import ImageUploader from '@/components/ImageUploader.vue'
import { DEFAULTS } from "@/global";
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
	components: { ImageUploader, Datepicker },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const dataList = ref<any[]>([]);

	const isBlue = ref<boolean>(process.env.VUE_APP_ENV === "blue")

	onMounted(async () => {
	});

    const cancel = () => {
      ctx.emit('cancel');
    }

	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		ctx.emit('confirm', toPostData(props.item, ['show']));
	}

	const imageUpload = (url: string) => {
		props.item.image = url
	}

    return {
      cancel,
	  confirm,
	  checkNumber,
	  imageUpload,
	  dataList,
	  isBlue,
    }
  },
});
</script>

<style scoped>
	.form-check-input {
		margin-left: 0;
	}
</style>

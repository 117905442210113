<template>
    <div class="tinymce-editor">
        <Editor v-model="dataValue"
                :init="{
					height: 300,
					statusbar: true,
					menubar: false,
					branding: false,
					images_upload_url: 'https://yuegege.cn/ygg/file/upload/tinymce',
					plugins: [
						'advlist autolink link image lists code',
						'charmap print preview hr anchor pagebreak',
						'searchreplace wordcount visualblocks visualchars fullscreen insertdatetime nonbreaking',
						'table emoticons template paste help'
					],
					toolbar: 'undo redo | styleselect | forecolor backcolor bold italic | alignleft aligncenter alignright alignjustify | image link code | emoticons | bullist numlist outdent indent | preview ',
					file_picker_types: 'image',
					images_file_types: 'jpg,jpeg,png',
				}">
        </Editor>
    </div>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref, watch } from "vue";
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
	components: { Editor },
	props: {
		modelValue: {
			type: String,
			default: "",
		},
	},
	emits: ['update:modelValue'],
	setup(props: any, ctx: any)
	{
		const dataValue = ref<string>(props.modelValue);
		watch([dataValue], async () => {
			ctx.emit("update:modelValue", dataValue.value);
		})

		onMounted(async () => {
		});

		return {
			dataValue
		}
	},
});
</script>

<style scoped>

</style>

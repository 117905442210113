<template>
  <div class="modal-backdrop" v-if="show">
     <div class="modal2">
        <div class="modal-header flex-row flex-align-center">
          <div class="text-center flex-1 modal-header-title">{{item.id ? '编辑' : '添加'}}</div>
          <button @click="cancel" class="modal-header-close">
			<svg width="16" height="16">
			  <path fill='#333' d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
			</svg>
          </button>
        </div>
        <div class="modal-body flex-row flex-wrap">
			<table class="table table-borderless">
				<tr><td style="width: 100px;"></td><td></td></tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*编号:</label></div></td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.no">
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*标题:</label></div></td><td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.title">
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>介绍:</label></div></td>
					<td>
						<div class="mb-1">
							<textarea class="form-control" v-model="item.content"></textarea>
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>适应人群:</label></div></td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.fit">
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>分组:</label></div></td>
					<td>
						<div class="mb-1">
							<input type="text" class="form-control" v-model="item.group">
						</div>
					</td>
				</tr>
        <td ><div class="mb-1 td-label-right"><label>分类:</label></div></td>
        <td>
          <div class="mb-1">
            <input type="text" class="form-control" v-model="item.chankangType">
          </div>
        </td>
				<tr>
					<td><div class="mb-1 td-label-right"><label>*价格:</label></div></td>
					<td>
						<div class="mb-1 flex-row">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.price">
							<input type="text" style="width: 120px;" class="form-control ml-2" v-model="item.unit" placeholder="单位">
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>体验价:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.trialPrice">
							<div class="ml-2">{{ item.unit }}</div>
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>闲时价:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.idlePrice">
							<div class="ml-2">{{ item.unit }}</div>
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>疗程价:</label></div></td>
					<td>
						<div class="mb-1 flex-row">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.packPrice">
							<input type="text" style="width: 120px;" class="form-control ml-2" v-model="item.packUnit" placeholder="单位">
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>疗程价2:</label></div></td>
					<td>
						<div class="mb-1 flex-row">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.packPrice2">
							<input type="text" style="width: 120px;" class="form-control ml-2" v-model="item.packUnit2" placeholder="单位">
						</div>
					</td>
				</tr>
<!--				<tr  v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>手工:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="number" style="width: 120px;" class="form-control" v-model="item.shougong">
							<div class="ml-2">{{ item.unit }}</div>
						</div>
					</td>
				</tr>-->
       <tr>
          <td class="td-top"><label>手工费:</label></td>
          <td>
            <div class="mb-1">
              <div class="mb-1 flex-row" v-for ="(value, idx) in item.shopShougongList" :key='idx'>
                <label>{{value.name}}</label>
                <input  type="text" style="width: 120px;" class="form-control" v-model="value.shougong">
              </div>
            </div>
          </td>
        </tr>
				<tr v-if="!isBlue">
					<td><div class="mb-1 td-label-right"><label>设备时长:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="text" style="width: 120px;" class="form-control" v-model="item.deviceTime">
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>总时长:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="text" style="width: 120px;" class="form-control" v-model="item.duration">
						</div>
					</td>
				</tr>
				<tr>
					<td><div class="mb-1 td-label-right"><label>备注:</label></div></td>
					<td>
						<div class="mb-1 flex-row flex-align-center">
							<input type="text" class="form-control" v-model="item.remark">
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>技师:</label></div></td>
					<td>
						<div class="mb-1 flex-row">
							<div v-for="(op, idx) in item.operator" :key='idx'>
								<img :src='op.headimg'  class="image-preview-small" @click="item.operator.splice(idx,1)" />
								<div class="flex-row-center mr-3">{{ op.name }}</div>
							</div>
							<img src="/assets/image_preview_add.png" class="image-preview-small" @click="showPickEmployee=true" />
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td><div class="mb-1 td-label-right"><label>仪器:</label></div></td>
					<td>
						<div class="mb-1 flex-row">
							<div v-for="(op, idx) in item.device" :key='idx'>
								<img :src='op.image'  class="image-preview-small" @click="item.device.splice(idx,1)" />
								<div class="flex-row-center mr-3">{{ shortenTitle(op.title, 6) }}</div>
							</div>
							<img src="/assets/image_preview_add.png" class="image-preview-small" @click="showPickDevice=true" />
						</div>
					</td>
				</tr>
				<tr v-if="isBlue">
					<td>
						<div class="mb-1 td-label-right"><label>项目明细:</label></div>
					</td>
					<td>
						<div class="mb-1 flex-row">
							<div v-for="(img, idx) in item.detail" :key='idx'>
								<img :src='img' style="width:150px;height:150px;cursor:pointer;" @click="item.detail.splice(idx,1)" />
							</div>
							<div class="mb-1"><ImageUploader @upload='imageUpload' /></div>
						</div>
					</td>
				</tr>
			</table>
        </div>
		<div class="modal-footer2 flex-row-center">
			<button type="button" class="btn btn-primary" @click="confirm">确定</button>
		</div>
    </div>
  </div>

  <PickEmployee v-if="showPickEmployee" @cancel="showPickEmployee=false" @confirm="addOperator" :shop="item.shop" job="美容师" />
  <PickPreset v-if="showPickDevice" @cancel="showPickDevice=false" @confirm="addDevice" :shop="item.shop" />
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, defineComponent, ref, watch } from "vue";
import { checkNumber, toPostData, checkResponseDataAndAlert, shortenTitle } from '@/common/util';
import PickEmployee from "./PickEmployee.vue";
import request from "@/global";
import PickPreset from "./PickPreset.vue";
import ImageUploader from '@/components/ImageUploader.vue'

export default defineComponent({
	components: { PickEmployee, PickPreset, ImageUploader },
	emits: ["confirm", "cancel"],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: {},
		},
	},

  setup(props: any, ctx: any)
  {
	const refData = reactive({
		showPickEmployee: false,
		showPickDevice: false,
		isBlue: process.env.VUE_APP_ENV === "blue"
	});
	const dataList = ref<any[]>([]);
  const shouGongList =  ref<any[]>([]);
    const cancel = () => {
      ctx.emit('cancel');
    }

	onMounted(async () => {
		loadShops();
	});

	// 加载列表
	const loadShops = async () => {
		try {
			const data = (await request({url: `shop/list/1/100`, get: true})).data;
			if (checkResponseDataAndAlert(data) && data.data) {
				dataList.value = data.data;
			}
		} catch (e) {
      console.log(e)
      console.log("exception")
			checkResponseDataAndAlert({});
		}
	};

	const addOperator = (ops: any[]) => {
		if (!props.item.operator) props.item.operator = [];
		for (const op of ops) props.item.operator.push(op);
		refData.showPickEmployee = false;
	}

	const addDevice = (ops: any[]) => {
		if (!props.item.device) props.item.device = [];
		for (const op of ops) props.item.device.push(op);
		refData.showPickDevice = false;
	}

	const imageUpload = (url: string) => {
		if (!props.item.detail) props.item.detail = [];
		props.item.detail.push(url)
	}

	const confirm = () => {
		// console.log(JSON.stringify(toPostData(props.item, [])))
		const postItem = JSON.parse(JSON.stringify(props.item));
		if (postItem.operator)
			postItem.operator = postItem.operator.map((t:any)=>{return {id: t.id, name: t.name}});
		ctx.emit('confirm', toPostData(postItem, []));
	}

	const dataRef = toRefs(refData);
    return {
      cancel,
	  confirm,
	  checkNumber,
	  addOperator,
	  addDevice,
	  dataList,
    shouGongList,
	  shortenTitle,
	  imageUpload,

	  ...dataRef
    }
  },
});
</script>

<style scoped>
.form-check-input {
	margin-left: 0;
}
.image {
	width: 150px;
	height: 150px;
	margin: 0 5px 5px 5px ;
	background-color: #00193A;
}
</style>
